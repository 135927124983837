
body {
    margin: 0;
    font-family: "Titillium Web", "Arial", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #211A1D;   
    color: #F8F0FB;
}
:hover {
    transition: 0.2s linear;
}

